import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BusinessCardPage from './components/BusinessCardPage';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/bc/:ref" element={<BusinessCardPage />} />
      </Routes>
    </Router>
  );
}

export default App;


