 
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const BusinessCardPage = () => {
  const { ref } = useParams(); // Fetch the 'ref' parameter from the URL
  const [cardData, setCardData] = useState(null);

  useEffect(() => {
    // Fetch data from API using ref number
    axios.get(`http://localhost:5000/api/card/${ref}`) // Assuming backend runs locally on port 5000
      .then(response => setCardData(response.data))
      .catch(error => console.error('Error fetching business card data:', error));
  }, [ref]);

  return (
    <div>
      {cardData ? (
        <div>
          <h1>{cardData.name}</h1>
          <p>{cardData.company}</p>
          <p>{cardData.position}</p>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default BusinessCardPage;